import { StoreEnhancer } from "redux";

import {
	ApplicationFactories,
	ApplicationSetup,
	ModuleRegistryProvider
} from "@com.mgmtp.a12/bap-client/lib/core/application";
import { ApplicationModel } from "@com.mgmtp.a12/bap-client/lib/core/model";

import * as appModel from "./appmodel.json";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleProvider } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view/internal/view";
import { configure as configurePlatformServerConnectors } from "@com.mgmtp.a12/bap-client/lib/extensions/platform-server-connectors";
import { DataHandlers } from "@com.mgmtp.a12/bap-client/lib/core/data";
import { CRUDFactories } from "@com.mgmtp.a12/bap-client/lib/extensions/crud";
import {
	bapFormEngineDataReducers,
	bapFormEngineSagas,
	createBapFormEngineMiddlewares
} from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { createViewComponentProvider } from "./containerFactory";
import { PRODUCT_PAGE_MODULES, SETUP_ACTIONS, REDUCER_MAP } from "np-broker-portal-bap-module/lib";
import { ExtendedModule } from "np-broker-portal-bap-module/lib/modules/basler/basler-module";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { combineExternalEnumProviders } from "ggw-customer-portal-common/lib/utils";
import IExternalEnumerationProvider from "@com.mgmtp.a12/bap-form-engine/lib/back-end/services/external-enumeration-provider";

export function setup(delay: number): {
	config: ApplicationSetup,
	viewComponentProvider: View.ComponentProvider,
	restoreAuthentication(): Promise<void>
} {
	const platformServerConnectors = configurePlatformServerConnectors({
		serverURL: `${contextPath}/api`,
		localeProvider: () => Container.config.get<LocaleProvider>(Container.identifier.LocaleProvider).get(),
		userProvider: () => {
			if (config === undefined) {
				throw new Error("broken setup");
			}
			return AuthenticationSelectors.user()(config.store.getState());
		}
	});

	PRODUCT_PAGE_MODULES.forEach(m => {
			if ((m as ExtendedModule).setConnectorLocator) {
				(m as ExtendedModule).setConnectorLocator(ConnectorLocator.getInstance().getServerConnector());
			}
		ModuleRegistryProvider.getInstance().addModule(m);
	});

	const dataHandlers: DataHandlers = {
		dataEditors: [],
		dataLoaders: [
			platformServerConnectors.loaders.overviewDataLoader,
			platformServerConnectors.loaders.documentDataLoader
		],
		modelLoaders: [
			platformServerConnectors.loaders.modelLoader
		],
		kernelCodeLoaders: platformServerConnectors.loaders.kernelCodeLoader
			? [platformServerConnectors.loaders.kernelCodeLoader]
			: [],
		dataProviders: [
			ApplicationFactories.createEmptyDocumentDataProvider()
		]
	};

	const combinedEnumProvider = combineExternalEnumProviders(PRODUCT_PAGE_MODULES.filter(module => (module as ExtendedModule).externalEnumerationProvider)
		.map(module => (module as ExtendedModule).externalEnumerationProvider)
		.filter((enumProvider): enumProvider is IExternalEnumerationProvider => !!enumProvider));

	const config = ApplicationFactories.createApplicationSetup({
		model: appModel as ApplicationModel,
		dataHandlers,
		storeEnhancer: enableReduxDevTools(),
		overridePlatformSagas: [
			...platformServerConnectors.sagas
		],
		customSagas: [
			...CRUDFactories.createSagas(),
			...bapFormEngineSagas
		],
		additionalMiddlewares: [
			...createBapFormEngineMiddlewares({externalEnumerationProvider: combinedEnumProvider}),
			CRUDFactories.createCRUDMiddleware()
		],
		preComputeNewDocuments: true,
		setupActions: SETUP_ACTIONS,
		dataReducers: bapFormEngineDataReducers,
		reducerMap: REDUCER_MAP
	});

	const viewComponentProvider = createViewComponentProvider(config.store, combinedEnumProvider);
	const restoreAuthentication = async () => {
		if (config === undefined) {
			throw new Error("broken setup");
		}
		return platformServerConnectors.restoreAuthenticationState(config.store.dispatch);
	};

	return {
		config,
		viewComponentProvider,
		restoreAuthentication
	};
}

declare var window: Window & { __REDUX_DEVTOOLS_EXTENSION__?(): StoreEnhancer };

/**
 * Trick to enable Redux DevTools with TS: see https://www.npmjs.com/package/redux-ts
 */
function enableReduxDevTools(): StoreEnhancer | undefined {
	return typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: undefined;
}

/**
 * Read context path from metadata in head of index.html
 */
function getContextPath(): string {
	const metaData: HTMLMetaElement | null = document.head.querySelector("[name='context.path'][content]");
	if (metaData) {
		return metaData.content || "";
	}
	return "";
}

export const contextPath = getContextPath();
