/**
 * Application configuration for development.
 *
 * Configures configuration and services.
 */
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleFactories } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Locale } from "@com.mgmtp.a12/localization/lib/main/Locale";
import { DefaultValueLocalizerService, LocalizerChainService } from "@com.mgmtp.a12/localization/lib/main/services";
import { ConsoleLoggingStrategy } from "@com.mgmtp.a12/logging";
import { LogLevel } from "@com.mgmtp.a12/logging/api";

import resources from "./resources";

Container.config.bind(Container.identifier.LoggingStrategy).toConstantValue(
	new ConsoleLoggingStrategy(console, LogLevel.LOG)
);

Container.config.bind(Container.identifier.Locales).toConstantValue([
	Locale.fromString("de_DE")
]);

const localeProvider = LocaleFactories.createLocaleProvider(Locale.fromString("de_DE"));
Container.config.bind(Container.identifier.LocaleProvider).toConstantValue(localeProvider);

const chainedLocalizerService = new LocalizerChainService(
	new DefaultValueLocalizerService(),
	LocaleFactories.createLocalizedResourceBundleLocalizerService(resources)
);

Container.config.bind(Container.identifier.LocalizerService).toConstantValue(chainedLocalizerService);

const localizer = LocaleFactories.createLocalizer(chainedLocalizerService, localeProvider);
Container.config.bind(Container.identifier.Localizer).toConstantValue(localizer);
