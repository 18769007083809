// These imports must stay at the top because they define globals
import "./config/dev.config";
import "./static/static-page-imports";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { connect, Provider } from "react-redux";

import { AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { FrameFactories, FrameViews } from "@com.mgmtp.a12/bap-client/lib/core/frame";
import { PROGRESS_COMPONENT_PROVIDER, LOGIN_FOOTER } from "np-broker-portal-bap-module/lib";
import { NotificationViews } from "@com.mgmtp.a12/bap-client/lib/core/notification";

import { setup } from "./appsetup";

interface StateProps {
	readonly authenticated: boolean;
}

const progressComponentProvider = PROGRESS_COMPONENT_PROVIDER();

const Page = connect<StateProps>(
	function mapStateToProps(state) {
		return {
			authenticated: AuthenticationSelectors.state()(state) === "AUTHENTICATED"
		};
	}
)(function PageTemplate(props: StateProps): JSX.Element {
	const rootRegionRef: string[] = [];
	const RegionUi = FrameFactories.regionUiProvider(rootRegionRef);
	return props.authenticated
		? <RegionUi
			regionReference={rootRegionRef}
			layoutProvider={FrameFactories.layoutProvider}
			regionUiProvider={FrameFactories.regionUiProvider}
			viewComponentProvider={viewComponentProvider}
			progressComponentProvider={progressComponentProvider}
		/>
		: <FrameViews.LoginPage
			logoURL={"images/northport-logo.svg"}
			enablePasswordReset={true}
			additionalFooterItems={React.createElement(LOGIN_FOOTER)}
		/>;
});

const mountpoint = document.createElement("div");
document.body.appendChild(mountpoint);

const delay = 3000;
const { config, viewComponentProvider, restoreAuthentication } = setup(delay);

restoreAuthentication().then(() =>  {
	ReactDOM.render(
		<Provider store={config.store}>
			<NotificationViews.Frame>
				<Page/>
			</NotificationViews.Frame>
		</Provider>,
		mountpoint
	);
});
