/**
 * This file contains shared imports of the static pages to simplify the preparation of providing modules from
 * the main bundle (e.g. react) to each static page bundle without bundling everything into the static page bundles.
 */
import * as React from "react";

import { ActionContentbox } from "@com.mgmtp.a12/widgets/lib/contentbox/main/action-contentbox/action-contentbox.view";
import { ContentBoxElements } from "@com.mgmtp.a12/widgets/lib/contentbox/main/template/contentbox.tpl.view";

declare const window: Window & {
	modules?: { [key: string]: object; };
};

window.modules = window.modules || {};
window.modules["static-page-imports"] = {
	React,
	ContentBoxElements,
	ActionContentbox
};

export {
	React,
	ContentBoxElements,
	ActionContentbox
};
