import * as React from "react";

import { FrameFactories } from "@com.mgmtp.a12/bap-client/lib/core/frame";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { StaticPageFactories } from "@com.mgmtp.a12/bap-client/lib/extensions/static-page";
import { FormEngineViews } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { CRUDViews } from "@com.mgmtp.a12/bap-client/lib/extensions/crud";
import { ModuleRegistryProvider } from "@com.mgmtp.a12/bap-client/lib/core/application";
import { Store } from "redux";
import IExternalEnumerationProvider from "@com.mgmtp.a12/bap-form-engine/lib/back-end/services/external-enumeration-provider";

const staticPageComponentProvider = StaticPageFactories.createViewComponentProvider();

export function createViewComponentProvider(store: Store, externalEnumProvider: IExternalEnumerationProvider): View.ComponentProvider {
	return ModuleRegistryProvider.getViewComponentProvider(store.getState(), fallbackViewComponentProvider(externalEnumProvider));
}

function fallbackViewComponentProvider(externalEnumProvider: IExternalEnumerationProvider): (componentName: string) => React.ComponentType<View> {
	const standardComponentProvider = createStandardComponentProvider(externalEnumProvider);
	return (componentName: string) => { return staticPageComponentProvider(componentName)
		|| Views[componentName]
		|| FrameFactories.viewComponentProvider(componentName)
		|| standardComponentProvider(componentName)
		|| Placeholder;
	};
}

function Placeholder(props: View): JSX.Element {
	return <div>ERROR: NO CONTAINER FOUND</div>;
}

function createStandardComponentProvider(externalEnumProvider: IExternalEnumerationProvider): (componentName: string) => React.ComponentType<View> | undefined {
	const components: { [name: string]: React.ComponentType<View> | undefined } = {
		OverviewCRUD(props) {
			return <CRUDViews.OverviewEngineView {...props} />;
		},
		BAPFormEngine(props) {
			return <FormEngineViews.FormEngine {...props} externalEnumerationProvider={externalEnumProvider} />;
		}
	};
	return function CRUDComponentProvider(name) { return components[name]; };
}

const Views: { [name: string]: React.ComponentType<View> } = {
	// default views can go here if needed
};
