/**
 * This resource definition contains the resources being used by all registered BAP modules besides 
 * the ones that are use for the actual bap client of this project.
 *  
 * When rebinding the localizerService to a new instance of a ChainedLocalizerService containing 
 * the original one as parent and the new resources as delegate not all resources can't be accessed 
 * anymore for whatever reason. This for example involves the application title that is not found
 * during application setup.
 */
import { LOCALE_RESOURCE_KEYS, LocalizedResourceBundle } from "@com.mgmtp.a12/bap-client/lib/core/locale";

const resources: LocalizedResourceBundle = {
	en: {
		// there is an error during application setup if that key is missing for locale en
		[LOCALE_RESOURCE_KEYS.application.title]: "Maklerportal"
	},
	de: {
		"offer.creation.error.title": "Angebotsanfrage",
		"offer.creation.error": "Die Angebotsanfrage konnte aufgrund eines technischen Fehlers nicht abgeschlossen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an wassersport@northport.eu.",
		"offer.creation.error.inputs": "Die Angebotsanfrage konnte aufgrund fehlender oder fehlerhafter Daten nicht abgeschlossen werden. Bitte überprüfen Sie Ihre Angaben und nutzen Sie die Möglichkeit zur Prüfung auf Vollständigkeit bei jedem Schritt.",
		"policy.creation.error.title": "Policierung",
		"policy.creation.error": "Die Policierung konnte aufgrund eines technischen Fehlers nicht abgeschlossen werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an wassersport@northport.eu.",
		"policy.creation.error.conflict": "Diese Angebotsanfrage wurde bereits durch einen IPP northport Underwriter policiert. Bei Rückfragen melden Sie sich bitte unter wassersport@northport.eu.",
		"premium.calculation.error.title": "Datenabfrage",
		"premium.calculation.error": "Die Prämie konnte aufgrund eines technischen Fehlers nicht berechnet werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an wassersport@northport.eu.",
		"premium.calculation.error.boatDb": "Für Ihre Angebotsanfrage stehen derzeit keine Daten aus der Bootsdatenbank zur Verfügung. Bitte versuchen Sie es später noch einmal."
	}
};

export default resources;
